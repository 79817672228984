import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  exports: [TranslateModule],
  providers: [TranslateService],
})
export class TranslateSharedModule {
  constructor(private translate: TranslateService) {
    
    var language = sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : "en";
    translate.setDefaultLang(language);
    translate.use(language);
  }

  showTranslator(txt) {
    return this.translate.get(txt).toPromise();
  }
}
