import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Global } from 'src/app/core/global.service';
import { Company } from 'src/app/interfaces/company';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.css']
})
export class FullscreenComponent implements OnInit {

  @Output() fechar = new EventEmitter<void>();
  @Input() data: any;

  public companyData: Company;

  public env = environment;
  public dateTimeNow: Date | string = new Date();

  fecharFullscreen() {
    this.fechar.emit();
  }

  constructor(private global: Global) { }

  ngOnInit(): void {
    this.dateTimeNow = new Date();
    this.companyData = this.global.getLoggedCompany();
  }

}
