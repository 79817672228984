import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-moxie-progress-bar',
  templateUrl: './moxie-progress-bar.component.html',
  styleUrls: ['./moxie-progress-bar.component.css']
})
export class MoxieProgressBarComponent implements OnInit {

  @Input() target: any;
  @Input() seconds: number = 0;

  constructor() { }

  progressValue: number = 0; // Valor inicial da barra de progresso
  interval: any;

  ngOnInit() {

    if (this.seconds) {

      const step = 100 / (this.seconds * 10);

      this.interval = setInterval(() => {
        
        if (this.progressValue < 100) {
          this.progressValue += step;
        } else {
          clearInterval(this.interval);
        }
      }, 100);
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval); // Limpa o intervalo quando o componente for destruído
    }
  }

}
