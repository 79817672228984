import { MainFilter } from "./../interfaces/main-filter";
import { InvokerService } from "./invoker.service";
import { Customer } from "./../interfaces/customer";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Company } from "../interfaces/company";
import * as moment from "moment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { DefaultLanguageOptions } from "../interfaces/default-language-options";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { environment } from "src/environments/environment";
import { Error } from "../interfaces/error";
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Industry } from "../interfaces/industry";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class Global {

  protected host: string = environment.startup;
  public env: any = environment;

  public mainFilter: MainFilter = {};
  public dataList: any = [];
  public current: boolean;
  public totalItems: any;

  public countryId: string | null = null;

  public mediaLoaded: any = null;

  public openPhotoFileDialog: boolean = false;

  public postType: any = {
    PHOTO: 1,
    VIDEO: 2,
    CHALLENGE: 3,
    DOCUMENTS: 4,
    FIND_STARTUPS: 5,
    PROGRAM: 6,
    CELEBRATE: 7,
    OFFER_HELP: 8,
    LINKS: 9,
    GENERIC: 10,
    POLL: 11,
    STEP_POST: 12
  };

  public pronoun: any = {
    SHE_HER: 1,
    HE_HIM: 2,
    THEY_THEM: 3,
    CUSTOM: 4
  };

  public loginStatus: any = {
    ACCOUNT_NOT_ACTIVE: 91,
    ACCOUNT_BLOCKED: 92,
    AUTHENTICATION_FAILED: 93,
    ACCOUNT_COMPANY_WITHOUT_INDUSTRY: 94,
    ACCOUNT_EMAIL_NOT_CONFIRMED: 95,
    ACCOUNT_WITHOUT_COMPANY: 96
  };

  public reactionType: any = {
    LIKE: 1,
    SUPPORT: 2,
    CELEBRATE: 3,
    CURIOUS: 4,
    LOVE: 5
  };

  public Target: any = {
    EVERYONE: 1,
    ADMINISTRATORS: 2,
    MY_NETWORK: 3,
    PRIVATE: 4
  }

  public statusView: any = {
    OPEN: 1,
    CANCEL: 2,
    FINISHED: 3
  }

  public companyProgramStatus: any = {
    APPLY: 1,
    ACCEPT: 2,
    DENY: 3,
    WITHDRAW: 4,
    REVOKE: 5
  }

  public reportPostReason: any = {
    SUSPICION: 1,
    ADULT_CONTENT: 2,
    INTELLECTUAL_PROPERTY: 3,
    VIOLENCE: 4,
    INAPPROPRIATE_CONTENT: 5
  }

  public typeAnswer: any = {
    NUMBER: 1,
    BOOLEAN: 2,
    TEXT: 3,
    DATE: 4,
    LIST: 5
  }

  public journeyStatus: any = {
    OPEN: 1,
    CANCEL: 2,
    FINISHED: 3,
    DEACTIVATE: 4
  }

  public journeyIntroduction: any = {
    PHOTO: 1,
    VIDEO: 2
  }

  public executedStepStatus: any = {
    COMPLETED: 1,
    NOT_COMPLETED: 2
  }

  public staffRoleTypes: any = {
    MENTOR_ADVISOR: 1,
    PARTNER: 2
  }

  private userSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  private companySubject: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);

  private copilotSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public copilot$: Observable<any> = this.copilotSubject.asObservable();

  private moxieSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public moxie$: Observable<any> = this.moxieSubject.asObservable();

  public user$: Observable<Customer> = this.userSubject.asObservable();
  public company$: Observable<Company> = this.companySubject.asObservable();
  public error: Error;

  constructor(
    public toaster: ToastrService,
    private invoker: InvokerService,
    private router: Router,
    private _location: Location,
    private http: HttpClient
  ) {
    const loggedUser = this.getLoggedUser();
    const loggedCompany = this.getLoggedCompany();

    if (loggedUser) {
      this.setUserData(loggedUser);
    }

    if (loggedCompany) {
      this.setCompanyData(loggedCompany);
    }

  }

  ngOnInit(): void { }

  askToGpt(prompt: string, max_tokens: number, temperature: any, role_system?: string) {

    if (!prompt) {
      console.log('Erro: The parameter "prompt" must be provided.');
      return;
    }

    const url = 'https://asktogpt.netlify.app/.netlify/functions/askgpt';

    let params = new HttpParams();
    params = params.set('prompt', prompt);
    params = params.set('max_tokens', (max_tokens ?? 10).toString());
    params = params.set('temperature', (temperature ?? 0.7).toString());
    params = params.set('role_system', role_system ?? "you are a specialist in startups, whenever a list or table is requested or a listing or table is returned, always return it in json without prefix or sufix messages");

    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Content-Type', 'application/json');

    this.http.get(url, { params, headers })
      .subscribe(
        (response: any) => {

          // extract of any json into string if exists
          const regex = /```([\s\S]*?)```/;
          const match = response.answer.match(regex);

          return {
            answer: (match && match.length > 1) ? JSON.parse(match[1]) : response.answer,
            type: (match && match.length > 1) ? "json" : "string"
          };

        },
        (error: any) => {
          return {
            answer: error,
            type: "error"
          };
        }
      );
  }

  backLastPage() {
    this._location.back();
  }

  generateUuid(term?: string) {
    return (term) ? term + '-' + uuidv4() : uuidv4();
  }

  canonical(text: string) {
    return (typeof text === "string") ? text.trim().toLowerCase().replace(/\s+/g, '-') : text;
  }
  callSpeech(text: string, lang: string) {
    console.log("o browser vai falar:", text);
    let utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = lang;
    utterance.volume = 0.5;
    speechSynthesis.speak(utterance);
  }

  public setCopilotData(data: any) {
    this.copilotSubject.next(data);
  }

  public setMoxieData(data: any) {
    this.moxieSubject.next(data);
  }

  public setUserData(data: Customer) {
    if (data == null) {
      localStorage.removeItem("customer");
    } else {
      this.userSubject.next(data);
      localStorage.setItem("customer", JSON.stringify(data));

      if (data) {
        if (data.language.name) {
          sessionStorage.setItem("language", String(data.language.name));
        } else {
          sessionStorage.setItem("language", String(data.language));
        }
      }
    }
  }

  public setCompanyData(data: Company) {
    if (data == null) {
      localStorage.removeItem("company");
    } else {
      this.companySubject.next(data);
      localStorage.setItem("company", JSON.stringify(data));
    }
  }

  public cloneField(objects, from, target) {
    return objects.map(obj => ({ ...obj, [target]: obj[from] }));
  }

  public getLoggedUser(): Customer | null {
    if (localStorage.getItem("customer")) {
      return JSON.parse(localStorage.getItem("customer"));
    }
    return null;
  }

  public setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; domain=.stephubs.com;";
  }
  public getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  public eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public getLoggedCompany(): Company | null {
    if (localStorage.getItem("company")) {
      return JSON.parse(localStorage.getItem("company"));
    }
    return null;
  }

  public signout(redirect = true): void {
    this.invoker.entityName = "signout";
    this.invoker.createResource(null).subscribe(
      (response: any) => {
        this.makeLogout(redirect);
      },
      (error: any) => {
        this.makeLogout(redirect);
      }
    );
  }

  public callUrlToStartup() {
    if (localStorage.getItem("customer")) {
      let customer: Customer = JSON.parse(localStorage.getItem("customer"));
      window.open(this.host + "/redirecting?user=" + customer.id, '_blank');
    }
  }

  public urlStartup(url: string, type: string | null = null): void {
    switch (type) {
      case 'company':
        window.open(url, '_blank');
        break;
      default:
        window.open(this.host + url, '_blank');
        break;
    }

  }

  public makeLogout(redirect = true) {

    this.eraseCookie('publicValidator');

    this.deleteAllCookies();

    localStorage.clear();
    sessionStorage.clear();

    this.setUserData(null);
    this.setCompanyData(null);

    if (redirect) {
      this.router.navigate(["/account/login"]);
    }
  }

  public getToken(): string {
    return localStorage.getItem("token");
  }

  public checkOwner(type: string, id: number) {
    return type == 'company' ? this.getLoggedUser().company.id == id : this.getLoggedUser().id == id;
  }

  public deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  public getRouteByPostType(postType: number, url: string, challenge: number | null, program: number | null): string {

    let value: string | null;

    switch (postType) {
      case 3:
        value = '/challenge/' + challenge;
        break;

      case 6:
        value = '/ecosystem/program/' + program;
        break;

      default:
        value = url;
        break;
    }

    return value;
  }

  public isLogged(): boolean {
    return (localStorage.getItem('customer')) ? true : false;
  }

  setDefaultLanguage() {

    if (!sessionStorage.getItem("language")) {
      let language = navigator.language || window.navigator.language;
      language = language.substring(0, 2);
      if (language) {
        sessionStorage.setItem("language", language);
      }
    }

  }

  public getDefaultLanguage(): string {
    return sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : "en";
  }

  public trimString(string: string, length: number) {
    if (string == null) {
      return string;
    } else {
      return string.length > length ?
        string.substring(0, length - 3) + "..." :
        string;
    }
  }

  public defaultLanguageOptions(): DefaultLanguageOptions {
    let defaultLanguage: string = this.getDefaultLanguage();

    let defaultLanguageOptions: DefaultLanguageOptions = {};

    switch (defaultLanguage) {
      case 'en':
        defaultLanguageOptions.currency = '$';
        defaultLanguageOptions.thousands = ',';
        defaultLanguageOptions.decimal = '.';
        break;

      case 'pt':
        defaultLanguageOptions.currency = 'R$';
        defaultLanguageOptions.thousands = '.';
        defaultLanguageOptions.decimal = ',';
        break;

      default:
        defaultLanguageOptions.currency = '$';
        defaultLanguageOptions.thousands = ',';
        defaultLanguageOptions.decimal = '.';
        break;
    }

    return defaultLanguageOptions;
  }

  public extractObjectByIdFromArrayOfObjects(ArrayOfObjects: any, id: number): any {
    return ArrayOfObjects.filter(obj => {
      return obj.id == id
    });
  }

  public convertDataToDefaultLanguage(date: Date) {
    if (this.getDefaultLanguage() == "pt") {
      return (
        moment(date).locale("pt").format("DD") +
        " de " +
        moment(date).format("MMM") +
        " de " +
        moment(date).format("YYYY")
      );
    }

    return (
      moment(date).format("MMM") +
      " " +
      moment(date).locale("en").format("DD") +
      ", " +
      moment(date).format("YYYY")
    );
  }

  public getSelectedCompany(): Company {
    return localStorage.getItem("company")
      ? JSON.parse(localStorage.getItem("company"))
      : null;
  }

  public setSelectedCompany(company): void {
    localStorage.setItem("company", JSON.stringify(company));
  }

  public bytesToMegaBytes(bytes): number {
    return bytes / (1024 * 1024);
  }

  public dateToTimeAgo(date: Date): string {
    return date ? moment(date).utc(true).fromNow() : null;
  }

  public getCurrentDate() {
    return new Date();
  }

  public formatDate(date: Date): string {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }

  public formatDateToHour(date: Date): string {
    return date ? moment(date).format('LT') : null;
  }

  public checkExistingFilters(filter: string, action: string): string {
    let mainFilter: MainFilter;
    let current: string;

    if (localStorage.getItem("mainFilter")) {
      mainFilter = JSON.parse(localStorage.getItem("mainFilter"));

      if (action == "delete") {
        current = "";
      } else {
        Object.entries(mainFilter).find((item) => {
          if (item[0] == filter) {
            current = "active";
          }
        });
      }

      return current;
    } else {
      mainFilter = {};
      localStorage.setItem("mainFilter", JSON.stringify(mainFilter));
      return "";
    }
  }

  public getFilterResult(): void {
    let i: number = 0;
    let query: string = "?";
    let pageNumber: number = 1;

    if (!Object.keys(this.mainFilter).length) {
      this.mainFilter = JSON.parse(localStorage.getItem("mainFilter"));
    }

    if (this.mainFilter == undefined) {
      this.mainFilter = {};
    }

    Object.entries(this.mainFilter).forEach((item: any) => {
      switch (item[0]) {
        case "company":
          query += `name=${item[1].name}&`;
          break;
        case "industry":
          query += `vertical=${item[1].id}&`;
          break;
        case "companyTypes":
          query += `type=${item[1].id}&`;
          break;
        case "country":
          query += `country=${item[1].id}&`;
          break;
        case "state":
          query += `state=${item[1].id}&`;
          break;
        case "hashtag":
          query += `text=${encodeURIComponent(item[1].name)}&`;
          break;
        case "text":
          query += `text=${encodeURIComponent(item[1])}&`;
          break;
        default:
          break;
      }
    });

    this.invoker.entityName = `companies${query}pageNumber=${pageNumber}&pageSize=10`;
    this.invoker.getResources().subscribe((response: any) => {
      this.totalItems = response;
      this.dataList = response;
      this.dataList.query = query;
      this.dataList.pageNumber = pageNumber;

      if (response.length > 0) {
        this.dataList.btnLoad = false;
      }
      else {
        this.dataList.btnLoad = true;
      }
    });
  }

  getJourneyStep(journeyId: number, page: number = 0): void {
    let i: number = 0;

    this.invoker.entityName = `companies/programs/${journeyId}/mentoring-steps/program-steps`;
    this.invoker.getResources().subscribe((response: any) => {
      this.totalItems = response;

      if (this.totalItems.length >= 1) {
        for (i = 0; i < 1; i++) {
          this.dataList[i] = this.totalItems[i];
          this.dataList.totalPages = Math.ceil((response.length / 1)) - 1;
          this.dataList.currentPage = page;
        }
      }
      else {
        this.dataList = this.totalItems;
      }

    });
  }

  checkStringContainsURL(string: string): boolean {
    if (string == "" || string == " ") {
      return false;
    }
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  getDomainFromURL(url: string | null): string {
    if (url == null) {
      return null;
    }
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi;
    return url.match(reg) != null ? new URL(url).hostname : null;
  }

  getUrlFromString(string: string): Array<string> | boolean {
    if (string == "" || string == " ") {
      return false;
    }

    var res = string.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);

    return res;
  }

  getEmbedData(url): Observable<any> {
    this.invoker.entityName = `html-metadata?url=${url}`;
    return this.invoker.getResources();
  }

  getCurrentBusinessPlan(): Observable<any> {
    this.invoker.entityName = 'strategyplan/planactive/company';
    return this.invoker.getResources()
    .pipe(
      map((response: any) => {
        return (response && response.id) ? true : false;
      }),
      catchError((error: any) => {
        return of(false);
      })
    );
  }

  truncate(str: string, n: number, useWordBoundary: boolean): string {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n - 1);
    
    return (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString);
  };

  getTargetId(targetDescription: string) {
    let targetId: number;

    switch (targetDescription) {
      case "Anyone":
        targetId = this.Target.EVERYONE;
        break;
      case "Connections only":
        targetId = this.Target.ADMINISTRATORS;
        break;
      case "My Network":
        targetId = this.Target.MY_NETWORK;
        break;
      case "Private":
        targetId = this.Target.PRIVATE;
        break;
      default:
        targetId = this.Target.EVERYONE;
        break;
    }

    return targetId;
  }

  isEmptyObject(obj: any): boolean {
    return (obj && (Object.keys(obj).length === 0));
  }

  getValueOrDefault(value: string): string {
    return value ? value : "-";
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.key;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeQuotes(value: string): string {
    return value.replace(/["']/g, "");
  }

  setError(error: Error | null): void {
    this.error = error;
  }
}
