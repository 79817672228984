import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { RouterModule } from "@angular/router";
import { MomentModule } from "ngx-moment";
import { CardListComponent } from "./card-list/card-list.component";
import { TranslateModule } from "@ngx-translate/core";
import { NavbarComponent } from "./navbars/navbar/navbar.component";
import { TeamComponent } from "./team/team.component";
import { List1Component } from "./list1/list1.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { BlankContentComponent } from "./blank-content/blank-content.component";
import { SearchCompanyComponent } from "./search-company/search-company.component";
import { GeneralMaturityIndexComponent } from './general-maturity-index/general-maturity-index.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { FooterGeralComponent } from "./footer/footer-geral/footer-geral.component";
import { FooterFeedComponent } from './footer/footer-feed/footer-feed.component';
import { SearchAutoCompleteComponent } from "./search-auto-complete/search-auto-complete.component";
import { FormsModule } from "@angular/forms";
import { ValuationMeasurementComponent } from './valuation-measurement/valuation-measurement.component';
import { AbbrMoneyPipe } from "../core/pipes/abbr-money.pipe";
import { ActionButtonsComponent } from "./action-buttons/action-buttons.component";
import { SafePipe } from "../core/pipes/safeUrl.pipe";
import { NavbarLoggedComponent } from './navbars/navbar-logged/navbar-logged.component';
import { LogoComponent } from './logo/logo.component';
import { SubscriptionComponent } from "./subscriptions/subscription/subscription.component";
import { ModalSubscriptionComponent } from "./subscriptions/modal-subscription/modal-subscription.component";
import { ModalUnauthorizedComponent } from "./subscriptions/modal-unauthorized/modal-unauthorized.component";
import { NotFoundComponent } from './not-found/not-found.component';
import { NavbarIncubatorComponent } from "./navbars/navbar-incubator/navbar-incubator.component";
import { DigitalIncubatorComponent } from './digital-incubator/digital-incubator.component';
import { InviteFriendComponent } from './invite-friend/invite-friend.component';
import { CompanyConnectComponent } from './company-connect/company-connect.component';
import { CustomerConnectComponent } from './customer-connect/customer-connect.component';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";
import { HandleErrorsComponent } from "./handle-errors/handle-errors.component";
import { SubscriptionPaymentComponent } from "./subscriptions/subscription-payment/subscription-payment.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ChartRadarComponent } from "./chart-radar/chart-radar.component";
import { NgChartsModule } from "ng2-charts";
import { NavbarEcosystemComponent } from "./navbars/navbar-ecosystem/navbar-ecosystem.component";
import { JoinJourneyComponent } from "./join-journey/join-journey.component";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { MostPendingStepAlertComponent } from './most-pending-step-alert/most-pending-step-alert.component';
import { CopilotComponent } from './copilot/copilot.component';
import { MoxieAnimatedComponent } from './moxie-animated/moxie-animated.component';
import { MoxieProgressBarComponent } from './moxie-progress-bar/moxie-progress-bar.component';
import { MoxieGenericCardSummaryComponent } from './moxie-generic-card-summary/moxie-generic-card-summary.component';
import { FullscreenComponent } from "./fullscreen/fullscreen.component";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { sanitizeHtmlPipe } from "../core/pipes/sanitize.pipe";
import { PaginationComponent } from './pagination/pagination.component';
import { GenericAddEmployeeComponent } from './generic-add-employee/generic-add-employee.component';
import { GenericSummaryCarouselComponent } from './generic-summary-carousel/generic-summary-carousel.component';

const components = [
  CardListComponent,
  NavbarComponent,
  NavbarLoggedComponent,
  NavbarIncubatorComponent,
  TeamComponent,
  List1Component,
  BlankContentComponent,
  SearchCompanyComponent,
  GeneralMaturityIndexComponent,
  BarChartComponent,
  SearchAutoCompleteComponent,
  ValuationMeasurementComponent,
  NotFoundComponent,
  SubscriptionComponent,
  ModalSubscriptionComponent,
  ModalUnauthorizedComponent,
  AbbrMoneyPipe,
  SafePipe,
  ActionButtonsComponent,
  LogoComponent,
  DigitalIncubatorComponent,
  InviteFriendComponent,
  CompanyConnectComponent,
  CustomerConnectComponent,
  FooterGeralComponent,
  FooterFeedComponent,
  HandleErrorsComponent,
  SubscriptionPaymentComponent,
  ChartRadarComponent,
  NavbarEcosystemComponent,
  JoinJourneyComponent,
  ConfirmationModalComponent,
  MostPendingStepAlertComponent,
  CopilotComponent,
  MoxieAnimatedComponent,
  MoxieProgressBarComponent,
  MoxieGenericCardSummaryComponent,
  FullscreenComponent,
  sanitizeHtmlPipe,
  PaginationComponent,
  GenericAddEmployeeComponent,
  GenericSummaryCarouselComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    RouterModule,
    MomentModule,
    FormsModule,
    TooltipModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    NgChartsModule,
    CarouselModule.forRoot()
  ],
  exports: [...components, TranslateModule, NgxSkeletonLoaderModule],
})
export class SharedComponentsModule {}
