import { Injectable, NgZone } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvokerService extends BaseService {

  constructor(
    public http: HttpClient,
    public zone: NgZone) {
    super(http);
  };

  public save(data = null) {
    if (data.id) {
      return this.updateResource(data)
    }
    else {
      return this.createResource(data);
    }
  }

  public getEventSource(params = {}): EventSourcePolyfill {
    const auth = localStorage.getItem("token");
    return new EventSourcePolyfill(this.resourceUrl(null,params),{
      withCredentials: true
    });
  }

  public getEventSourceWithGet(): Observable<any>  {    
    return new Observable(observer => {
      const eventSource = this.getEventSource();

      eventSource.addEventListener("feed",(event) => {
        this.zone.run(() => {
          observer.next(event);
        });
      });

      eventSource.onopen = () => {
        // console.log("Opening connection.Ready State is ", eventSource.readyState);
      };

      eventSource.onerror = (error) => {            
        this.zone.run(() => {
            observer.next(error);            
        });
      };
    });
  }

}
