import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from "./core/global.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {

  title = "StepHubs";

  public isLogged = false;

  constructor(
    private router: Router,
    private global: Global
  ) {
    this.global.setDefaultLanguage();
    this.isLogged = this.global.isLogged();
  }

  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

  }

}
