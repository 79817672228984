import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvokerService } from './invoker.service';

@Injectable({
  providedIn: 'root'
})
export class MoxiecallService {

  private apiUrl = 'https://10exrs9ip53688-5000.proxy.runpod.net/api/v1/generate';  // deprecated
  private apiChatUrl = 'https://3622-177-68-190-110.ngrok-free.app/api/v1/chat';   // 5000
  private wsUrl = 'ws://0a0a-177-68-190-110.ngrok-free.app/api/v1/chat-stream';   // 

  private connection: WebSocket;

  constructor(
    private http: HttpClient,
    private invoker: InvokerService
  ) {

    this.connection = new WebSocket(this.wsUrl);

  }

  makeMoxieCall(assistent: string, prompt: string, force?: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestBody = {
      prompt: assistent + "\nYou: " + prompt + "\nAssistant: ",
      stopping_strings: ["\nAssistant:", "\nYou:"]
    };

    const requestOptions = {
      headers
    };

    return this.http.post(this.apiUrl, requestBody, requestOptions);
  }

  async makeMoxieCallChatAsync(assistent: string, prompt: string, force?: boolean, _continue?: boolean, history?: any, copilotId?: number): Promise<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestBody: any = {
      regenerate: false,
      _continue: _continue,
      forceChat: "Y",
      copilotId: copilotId,
      baseUrl: "https://b947-201-43-120-170.ngrok-free.app/api/v1/chat"
    };

    console.log("estou chamando a makeMoxieCallChat, com esse requestBody: ", requestBody);

    if (history) {
      console.log("estou aqui porque existe continue e history");
      requestBody.history = history;
    }

    const requestOptions = {
      headers
    };

    this.invoker.entityName = "moxie";

    // Supondo que `this.invoker.createResource` seja uma função assíncrona, use `await` aqui
    const result = await this.invoker.createResource(requestBody);

    return result;
  }

  makeMoxieCallChat(assistent: string, prompt: string, force?: boolean, _continue?: boolean, history?: any, copilotId?: number): Observable<any> {

    console.log("tem history aqui:", history);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestBody: any = {
      regenerate: false,
      _continue: _continue,
      forceChat: "Y",
      copilotId: copilotId,
      baseUrl: this.apiChatUrl
    }

    console.log("estou chamando a makeMoxieCallChat, com esse requestBody: ", requestBody);

    if (history) {
      console.log("estou aqui porque existe continue e history");
      requestBody.history = history;
    }

    const requestOptions = {
      headers
    };

    this.invoker.entityName = "moxie";
    return this.invoker.createResource(requestBody);
  }

  connect(): Observable<Event> {
    return new Observable(observer => {
      this.connection.onopen = (event: Event) => observer.next(event);
      this.connection.onerror = (error: Event) => observer.error(error);
      this.connection.onmessage = (message: MessageEvent) => observer.next(message);
      this.connection.onclose = () => observer.complete();
    });
  }

  send(message: any): void {
    this.connection.send(JSON.stringify(message));
  }

  close(): void {
    this.connection.close();
  }

}
