import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-copilot',
  templateUrl: './copilot.component.html',
  styleUrls: ['./copilot.component.css']
})
export class CopilotComponent implements OnInit {

  // copilot
  public useCopilot: boolean = false;

  // moxie
  public moxie
  public useMoxie: boolean = true;
  public clickToStart: boolean = false;
  public pulseEffect: boolean = false;

  // timeouts
  public timeoutChangeCopilotStatus: any;
  public timeoutCallSpeech: any;
  public skipCopilotTimeoutId: any;
  public skiptCountdownTimeout: any;

  // loadings
  public copilotLoading: boolean = false;
  public firstAnswerLoading: boolean = true;

  public copilotStatus: boolean = false;

  public myJson: any;

  public moveMoxietoTop: any = false;

  public copilotId: any = null;
  public copilot: any = null;
  public copilots: any = null;
  public bkpCopilot: any = null;

  public skipCopilot: boolean = false;
  public skipCopilotCountdown: number = 50;

  public chatFirstResponse: any = null;
  public chatFirstResponseType: any;

  public moxiePresentation: any = "bubble";

  public moxieVisibility: any = true;

  constructor(
    private invoker: InvokerService,
    public global: Global,
    public router: Router
  ) {
    this.copilotLoading = true;
  }

  ngOnInit(): void {
    this.watchCopilotId();
    this.watchMoxie();
  }

  private watchCopilotId() {
    this.global.copilot$.pipe(finalize(() => this.finishLoading()))
      .subscribe({
        next: (response) => {
          if (response) {
            console.log("copilotId:", response);
            this.copilotId = response;
            this.copilotLoading = false;
            this.getCopilotData(this.copilotId);
          }
        },
        error: (error) => {
          // vou tratar depois
        }
      });
  }

  private watchMoxie() {
    this.global.moxie$.pipe()
      .subscribe({
        next: (response) => {
          if (response) {
            console.log("moxie:", response);
            this.moxiePresentation = response;

            if (this.moxiePresentation == 'exclusive') {
              // this.changeCopilotStatus(false); // disabled for now
              this.moxieVisibility = false; // we dont need moxie bubble in the moxie entire screen
            }
          }
        },
        error: (error) => {
          // vou tratar depois
        }
      });
  }

  getCopilots() {
    this.invoker.entityName = "copilots/list?active=yes";
    this.invoker.getResources().subscribe({
      next: (response: any) => {
        this.copilots = response;
      },
      error: (error) => {
      }
    });
  }

  // get copilot data
  getCopilotData(copilotId: number) {

    this.invoker.entityName = "copilots";

    this.invoker.getResource(copilotId).subscribe({
      next: (response: any) => {

        console.log("copilot aberto:", response);

        setTimeout(() => {

          this.setCopilotStatus(true);

          setTimeout(() => {

            this.global.callSpeech(response.clientQuestion, 'en-US');
            this.callChat(true);

          }, 2000);

        }, 2000);

        this.copilot = response;
      },
      error: (error) => {
      }
    });

  }

  setCopilotStatus(status: boolean) {
    this.copilotStatus = status;
  }

  callChat(status: boolean) {



    if (status == true) {

      this.getCopilots();

      setTimeout(() => {
        this.useCopilot = true;
        this.useMoxie = false;
      }, 2000);

    }

  }

  callMoxiePage() {
    window.open('/moxie', '_self');
  }

  changeCopilotStatus(status: boolean) {
    console.log("estou aqui em changeCopilotStatus");
    this.copilotStatus = !status;

    if (this.copilotStatus) {

      setTimeout(() => {

        console.log("entrei no primeiro setTimeout", this.moveMoxietoTop);

        console.log("moveMoxietoTop is", this.moveMoxietoTop);

        if (this.moxiePresentation == "bubble") {
          this.global.callSpeech('Hello, I am Moxie. How can I help you?', 'en-US');
        } else {
          this.global.callSpeech('Hello, I am Moxie. Use the prompt below to start.', 'en-US');
        }

        setTimeout(() => {

          console.log("entrei no segundo setTimeout", this.moveMoxietoTop);

          if (this.moxiePresentation == "bubble") {
            console.log("moxiePresentation is", this.moxiePresentation);
            this.moveMoxietoTop = false;
            this.clickToStart = !status;
            this.callChat(true);
            this.pulseEffect = !status;
            console.log("moveMoxietoTop is", this.moveMoxietoTop);
          } else {
            console.log("moxiePresentation is", this.moxiePresentation);
            // this.moveMoxietoTop = true; // disabled for now
            console.log("moveMoxietoTop is", this.moveMoxietoTop);
          }

        }, 4000);

      }, 1000);

    } else {
      this.clickToStart = !status;
      this.pulseEffect = !status;
    }

  }

  fnReturnCopilot() {
    this.skipCopilot = false;
    this.skipCopilotCountdown = 5;
    this.copilot = this.bkpCopilot;
    clearTimeout(this.skipCopilotTimeoutId);
    clearTimeout(this.skiptCountdownTimeout);
  }

  fnSkipCopilot() {
    this.skipCopilot = true;
    this.bkpCopilot = this.copilot;
    this.skipCopilotCountdown = 5;
    this.countdown(this.skipCopilotCountdown);
    this.skipCopilotTimeoutId = setTimeout(() => {
      this.copilot = null;
    }, 5000);
  }

  countdown(value: number) {
    this.skiptCountdownTimeout = setTimeout(() => {
      this.skipCopilotCountdown = value - 1;
      this.countdown(this.skipCopilotCountdown);
    }, 1000);
  }

  finishLoading() {
    setTimeout(() => {
      this.copilotLoading = false;
    }, 2000);
  }

  // search company by domain to check if already exists in our db
  getCompanyByDomain(domain: string) {
    console.log("entrei na getCompanyByDomain");
    this.invoker.entityName = `companies/profile/info?domain=${domain}`;
    this.invoker.getResources().subscribe((response: any) => {
      console.log("getCompanyByDomain:", response);
      return response;
    });
  }

  askToChatGPT(message: string, systemContext?: string, timer?: number, mock?: boolean): void {

    if (mock) {

      setTimeout(() => {

        let response = [
          {
            "name": "Accelerator XYZ",
            "site": "www.acceleratorxyz.com"
          },
          {
            "name": "HubTech",
            "site": "www.hubtech.com"
          },
          {
            "name": "Startup Hub",
            "site": "www.startuphub.com"
          },
          {
            "name": "Growth Accelerator",
            "site": "www.growthaccelerator.com"
          },
          {
            "name": "Tech Innovation Network",
            "site": "www.techinnovationnetwork.com"
          },
          {
            "name": "StartCo",
            "site": "www.startco.com"
          },
          {
            "name": "LaunchPad",
            "site": "www.launchpad.com"
          },
          {
            "name": "IncubateTech",
            "site": "www.incubatetech.com"
          }
        ];

        this.chatFirstResponse = response;
        this.chatFirstResponseType = "json";

      }, 2000);

    } else {

      if (isNaN(timer)) {
        timer = 0;
      }

      if (timer > 20000) {
        return
      }

      let payload = {
        "copilotId": 13,
        "question": message,
        "systemContext": systemContext ?? '',
        "forceChat": "Y"
      };

      this.invoker.entityName = `chatgpt`;
      this.invoker.createResource(payload).subscribe({
        next: (response: any) => {

          if (response.answerGPT == "Error call chatGPT") {
            timer = timer + 5000;

            this.chatFirstResponse = "Error to get data from chatGTP. waiting: " + timer + "ms to try again..";
            this.chatFirstResponseType = "error";

            setTimeout(() => {
              this.askToChatGPT(message, systemContext, timer, true);
            }, timer);

          } else {

            // extract of any json into string if exists
            const regex = /```([\s\S]*?)```/;
            const match = response.answerGPT.match(regex);

            this.chatFirstResponse = (match && match.length > 1) ? JSON.parse(match[1]) : response.answerGPT;
            this.chatFirstResponseType = (match && match.length > 1) ? "json" : "string";

          }
        },
        error: (error) => {
          this.chatFirstResponse = "Error to get data from chatGTP. waiting: " + timer + "ms to try again..";
          this.chatFirstResponseType = "error";
        }
      });

    }

  }

  oldAskToChatGPT(message: string, systemContext: string, timer?: number): void {

    if (isNaN(timer)) {
      timer = 0;
    }

    if (timer > 20000) {
      return
    }

    this.firstAnswerLoading = true;

    let payload = {
      "question": message,
      "systemContext": systemContext
    };

    this.invoker.entityName = `chatgpt`;
    this.invoker.createResource(payload).subscribe((response: any) => {

      if (response.answerGPT == "Error call chatGPT") {
        timer = timer + 5000;
        this.chatFirstResponse = "Error to get data from chatGTP. waiting: " + timer + "ms to try again..";
        this.chatFirstResponseType = "error";
        setTimeout(() => {
          this.askToChatGPT(message, systemContext, timer);
        }, timer);

      } else {

        // extract of any json into string if exists
        const regex = /```([\s\S]*?)```/;
        const match = response.answerGPT.match(regex);

        this.chatFirstResponse = (match && match.length > 1) ? JSON.parse(match[1]) : response.answerGPT;
        this.chatFirstResponseType = (match && match.length > 1) ? "json" : "string";

        this.firstAnswerLoading = false;

      }

    });

  }

}
