import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Customer } from 'src/app/_models';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { Company } from 'src/app/interfaces/company';
import { RoleControl } from 'src/app/interfaces/role-control';

@Component({
  selector: 'app-generic-add-employee',
  templateUrl: './generic-add-employee.component.html',
  styleUrls: ['./generic-add-employee.component.css']
})
export class GenericAddEmployeeComponent implements OnInit {

  public loading: boolean = false;
  public loadingSearch: boolean = false;
  public entity: any = {};
  public resultList = new Observable<any>();
  public typeInput$ = new Subject<string>();
  public company: Company;
  public rolesControl: RoleControl;
  public inviteUser: boolean = false;
  public role: any;

  public invitations: Array<any> = [];

  constructor(
    public global: Global,
    public bsModalRef: BsModalService,
    private invoker: InvokerService,
    private translate: TranslateService
  ) {
    this.company = this.global.getLoggedCompany();
  }

  ngOnInit(): void {
    this.entity = {};
    this.searchTerm();
    this.getRoles();
    this.getInvitations();
  }

  deleteInvitation(invitationId: number): void {

    console.log("entrei no deleteInvitation");

    this.loading = true;
    this.invoker.entityName = `email/invitation/${invitationId}`;

    this.invoker.deleteResource().pipe(finalize(() => {
      this.loading = false;
      this.bsModalRef.hide();
    })).subscribe(
      (response: any) => {
      },
      (error: any) => {
      }
    );
  }

  searchTerm(): void {
    this.resultList = concat(
      of([]), // default items
      this.typeInput$.pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.loadingSearch = true),
        switchMap(term => {
          this.invoker.entityName = `customers/list?name=${term}`;
          return this.invoker.getResource(null, null, term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.loadingSearch = false)
          )
        })
      )
    );
  }

  getRoles(): void {
    this.invoker.entityName = `role-control`;
    this.invoker.getResources().subscribe((response: any) => {
      this.rolesControl = response;
    });
  }

  setInviteUser(value: boolean): void {
    this.inviteUser = value;
  }

  getInvitations() {
    this.invoker.entityName = "email/invitation";
    this.invoker.getResources().subscribe((response: any) => {
      this.invitations = response;
    });
  }

  normalizeUserData(user: any) {
    console.log("search dentro do normalize: ", user);
    this.entity.firstName = user.firstName;
    this.entity.lastName = user.lastName;
    this.entity.email = user.email;
  }

  sendInvite(): void {

    if(!this.inviteUser) {
      this.normalizeUserData(this.entity.search);
    }    

    console.log("entity:", this.entity);

    console.log("item do search:", this.entity.search);

    let url = window.location.host;

    let invitedUserData = {
      "firstName": this.entity.firstName,
      "lastName": this.entity.lastName,
      "email": this.entity.email,
      "companyId": this.company.id,
      "companyName": this.company.name
    }

    console.log("invitedUserData:", invitedUserData);

    let body = {
      "firstName": this.entity.firstName,
      "lastName": this.entity.lastName,
      "email": this.entity.email,
      "typeInvitationId": (this.inviteUser) ? 5 : 6,
      "link": `${url}/onboarding/customer?invitedUser=${btoa(`${JSON.stringify(invitedUserData)}`)}`,
      "template": (this.inviteUser) ? "INVITE_JOIN_STEPHUBS_COMPANY" : "COMPANY_CONNECTED",
      "roleControl": this.entity.role,
      "role": this.role.id
    };

    console.log("body novo:", body);

    this.loading = true;
    this.invoker.entityName = `email/invitation`;
    this.invoker.createResource(body).pipe(finalize(() => {
      this.bsModalRef.hide();
      this.loading = false;
    })).subscribe(
      (response: any) => {
        // nothing to do for now
      },
      (error: any) => {
        // nothing to do
      }
    );
  }

}
