import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { start } from 'repl';

@Injectable({
  providedIn: 'root'
})
export class FormatTextService {

  processedText: string;
  tables: string[] = [];

  constructor(private sanitizer: DomSanitizer) {

  }

  checkifHasTable(text: string): boolean {

    let startsWith = "\n\n|";
    let endsWith = "|\n\n";

    if (text.includes(startsWith) && text.includes(endsWith)) {
      console.log("existe uma tabela nesse texto");
      return true;
    } else {
      console.log("nao existe uma tabela nesse texto");
      return false;
    }
  }

  exportarParaExcel() {
    var table = document.querySelector("table");
    var data = table.querySelectorAll("td");

    var csv = "";
    for (var i = 0; i < data.length; i++) {
      csv += data[i].textContent + ",";
    }

    var blob = new Blob([csv], { type: "text/csv" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "projecoes-financeiras.csv";
    link.click();
  }

  renderJson(string) {
    let processed = this.processText(string);
    let jsoned = this.extractTableData(processed);
    return jsoned;
  }

  extractTableData(html: string | SafeHtml): any[] {
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/gi;
    const trRegex = /<tr[^>]*>[\s\S]*?<\/tr>/gi;
    const tdRegex = /<td[^>]*>[\s\S]*?<\/td>/gi;

    // Converte SafeHtml para string, se necessário
    const htmlString = (typeof html === 'string') ? html : html.toString();

    // Adiciona verificações para garantir que htmlString seja uma string válida
    if (typeof htmlString !== 'string') {
      console.error('Input HTML is not a valid string.');
      return [];
    }

    const tables: any = htmlString.match(tableRegex) || [];

    const mergedData = tables.reduce((accumulator: any[], table, tableIndex) => {
      const rows = table.match(trRegex) || [];

      const headerRow = rows.shift(); // Remove a primeira linha (cabeçalho)
      const headers = headerRow.match(tdRegex).map(cell => cell.replace(/<\/?[^>]+(>|$)/g, '').trim());

      const rowData = rows.map((row, rowIndex) => {
        const cells = row.match(tdRegex) || [];
        const cellData = cells.reduce((acc: any, cell, cellIndex) => {
          const header = headers[cellIndex] || `column_${cellIndex + 1}`;
          const value = cell.replace(/<\/?[^>]+(>|$)/g, '').trim();
          acc[header] = value;
          return acc;
        }, {});

        return cellData;
      });

      return [...accumulator, ...rowData];
    }, []);

    return mergedData;
  }

  detectDataType(input: string): 'json' | 'table' | null {
    try {
      // Tenta fazer o parsing como JSON
      JSON.parse(input);
      return 'json';
    } catch (error) {

      // Verificar primeiro o padrão '[Table format]'
      const tableFormatRegex = /\[Table format\]([\s\S]+?)\|/;
      if (tableFormatRegex.test(input)) {
        return 'table';
      }

      // Se não encontrar, verificar o padrão '[Table]'
      const tableRegex = /\[Table\]([\s\S]+?)\|/;
      if (tableRegex.test(input)) {
        return 'table';
      }

      // Se ainda não encontrar, verificar o padrão antigo de tabela
      const oldTableRegex = /\n\n\|(.+?)\|\n\n/gs;
      if (oldTableRegex.test(input)) {
        return 'table';
      }

      // Se não for possível determinar se é JSON ou tabela, retorna null
      return null;

    }
  }

  findTablesStandart(text, regex) {

    let tableCounter = 0;
    let replacedText = text;

    replacedText = replacedText.replace(regex, (match, tableContent) => {
      tableCounter++;
      const tableMarker = `TABLE_PLACE_${tableCounter}`;
      this.tables.push(tableContent.trim());
      return tableMarker;
    });

    return this.tables.length;

  }

  extractAndValidateJson(input: string): object | null {
    try {
      // Procura por substrings que possam conter JSON
      const jsonCandidates = input.match(/\{(?:.|\n)*?\}/g) || [];

      // Tenta fazer o parsing de cada substring como JSON
      for (const jsonCandidate of jsonCandidates) {
        const parsedJson = JSON.parse(jsonCandidate);

        // Se o parsing for bem-sucedido, retorna o JSON
        if (parsedJson && typeof parsedJson === 'object') {
          return parsedJson;
        }
      }
    } catch (error) {
      // Se ocorrer um erro, significa que não é um JSON válido
      return null;
    }

    // Se não encontrar nenhum JSON válido, retorna null
    return null;
  }

  jsonToHtmlTable(json: object): string {

    if (!json || typeof json !== 'object') {
      // Verifica se o argumento é um JSON válido
      throw new Error('Invalid JSON');
    }

    // Inicia a construção da tabela HTML
    let htmlTable = '<table class="table table-light table-striped">';

    // Adiciona cabeçalho da tabela com base nas chaves do JSON
    const keys = Object.keys(json);
    const headerRow = keys.map(key => `<th>${key}</th>`).join('');
    htmlTable += `<thead><tr>${headerRow}</tr></thead>`;

    // Adiciona linhas da tabela com base nos valores do JSON
    const values = Object.values(json);
    const dataRows = values.map(value => {
      if (typeof value === 'object') {
        // Se o valor for um objeto, trata recursivamente
        const nestedRow = this.jsonToHtmlTable(value);
        return `<td>${nestedRow}</td>`;
      } else {
        return `<td>${value}</td>`;
      }
    }).join('');
    htmlTable += `<tbody><tr>${dataRows}</tr></tbody>`;

    // Fecha a tabela HTML
    htmlTable += '</table>';

    return htmlTable;
  }

  processText(text: string): any {

    const standardA = /\[Table format\]([\s\S]+?)\|/;
    const standardB = /\[Table\]([\s\S]+?)\|/;
    const standardC = /\n\n\|(.+?)\|\n\n/gs;

    let replacedText = text;

    // detect json or table standart
    if (this.detectDataType(text) === 'json') {

      let json = this.extractAndValidateJson(text);

      replacedText = this.jsonToHtmlTable(json);

    } else if (this.detectDataType(text) === 'table') {

      if (this.findTablesStandart(text, standardA) > 0 || this.findTablesStandart(text, standardB) > 0 || this.findTablesStandart(text, standardC) > 0) {

        console.log("padrão de tabela foi encontrado");

        const htmlTables = this.tables.map((table, index) => {

          const rows = table.split('\n').map(row => {

            const cells = row.split('|').filter(cell => cell.trim() !== '');

            if (cells.some(cell => cell.trim() === '---')) {
              return '';
            }

            const tdContent = cells.map(cell => `<td>${cell.trim()}</td>`).join('');
            return `<tr>${tdContent}</tr>`;

          }).join('');

          let finalTable = `<table id="table-${index}" class="table my-4 table-${index}">${rows}<caption><a id="ref-table-${index}">Exportar to Excel</a></caption></table>`;

          return finalTable;
        });

        // Substituir os marcadores pelas tabelas HTML no texto
        htmlTables.forEach((htmlTable, index) => {
          const marker = `TABLE_PLACE_${index + 1}`;
          replacedText = replacedText.replace(marker, htmlTable);
        });

      } else {
        console.log("novo padrão de tabela ou json, ou simplesmente nada foi retornado. VERIFICAR!");
      }
      
    } else {
      console.log("FATAL PROBLEM, NEEDS TO FIND JSON OR TABLE IN STRING");
    }

    replacedText = this.buildResultText(replacedText);

    return this.sanitizer.bypassSecurityTrustHtml(replacedText);
  }

  formatText(text: string): string {

    text = this.buildResultText(text);

    let hasTable = this.checkifHasTable(text);

    // Converte o texto para uma tabela
    if (text.includes('|') && text.includes('\n')) {
      return this.formatTable(text);
    }

    // Converte o texto para JSON
    // if (text.trim().startsWith('{')) {
    //   return this.formatJson(text);
    // }

    // // Converte o texto para uma lista
    // if (text.includes(',')) {
    //   return this.formatList(text);
    // }

    return text;
  }

  buildResultText(message: any): any {
    // Substituir quebras de linha por <br>
    message = message.replace(/\n/g, '<br>');

    // Substituir tabulações por &bull;
    message = message.replace(/\t/g, '&bull;');

    // Substituir **TEXTO** por <strong>TEXTO</strong>
    message = message.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    return message;
  }

  // Converte o texto para uma tabela
  formatTable(text: string): string {
    const rows = text.split('\n').filter(row => row.trim() !== '');
    const header = rows[0].split('|').map(col => col.trim());
    const tableBody = rows
      .slice(1)
      .map(row => row.split('|').map(col => col.trim()))
      .map(cols => `<tr>${cols.map(col => `<td>${col}</td>`).join('')}</tr>`)
      .join('');

    return `<table class="table table-light table-striped"><thead><tr>${header.map(col => `<th>${col}</th>`).join('')}</tr></thead><tbody>${tableBody}</tbody></table>`;
  }

  // Converte o texto para JSON
  formatJson(text: string): string {
    try {
      const json = JSON.parse(text);
      return `<pre>${JSON.stringify(json, null, 2)}</pre>`;
    } catch (error) {
      return 'Invalid JSON';
    }
  }

  // Converte o texto para uma lista
  formatList(text: string): string {
    const items = text.split(',').map(item => item.trim());
    return `<ul>${items.map(item => `<li>${item}</li>`).join('')}</ul>`;
  }

}
